/*
Navbar
*/

#header{
  position: fixed;
  top: 0;
  left: 0;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  background-color: #fafafa;
  line-height: 100px;
  font-size: 15px;
  z-index: 1000;
}


.home-landing{
  /*height: calc(100vh - 100px); */
  height: 100vh;
}



/* 
projects
*/

.my-projects{
  height: calc(100vh - 100px);
}

#myproject-title{
  color: #262121;
  font-weight: 700;
  font-size: xx-large;
}

@media screen and (max-width: 400px) {
  #myproject-title{
    padding-top: 2em;
  }
}
#myproject-title2{
  color: #323c24;
  font-weight: 100;
  font-size: xx-large;
}

@media only screen and (min-width: 768px) {
.left-side{
  width: 40%;
}
}

#leftside-background{
  background-color: #262121;
}
.project-title-text{
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #262121;
}

#made-with{
  color: #88807e;
}
.project-description{
  width: 450px;
 
}
@media screen and (max-width: 450px) {
  .project-description{
    width: 350px;
  
  }
}

#project-button{
  background-color: #303c4c;
}
@media screen and (max-width: 450px) {
.demo-button{
  display: none;
}
}
/*
Achievement section
*/

#acheivment-box{
  border-top: 1px solid #323c23;
  border-bottom: 1px solid #323c23;
  width: 450px;
}

@media screen and (max-width: 450px) {
  #acheivment-box{
    border-top: 1px solid #323c23;
    border-bottom: 1px solid #323c23;
    width: 350px;
  }
}
.acheivment-container{
  display: flex;
}

#achievement-text{
  color: #88807e;
  font-weight: bold;
  text-shadow: 0.7px 0.7px #ffffff;
}

.acheivment-bg{
  background-color: #dedddb;
}

#subtext-ach{
  font-style: italic;
}
/*
background icons
*/

.background-icons img{

  animation: icon 19s linear infinite;
  

}
.background-icons{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: -150px;
  z-index: -1;
  
  

}

@keyframes icon{

  0%{
      transform: translateY(0);
      opacity: 1;
  }
  50%{
      
      opacity: 1;
  }
  75%{
      
      opacity: 1;
  }
  100%{
      transform: translateY(-120vh);
      opacity: 1;
  }
}

.background-icons img:nth-child(1){
  animation-delay: 7s;
  width: 80px;
  
}
.background-icons img:nth-child(2){
  animation-delay: 1s;
  animation: icon 24s linear infinite;
}
.background-icons img:nth-child(3){
  animation-delay: 12s;
}
.background-icons img:nth-child(4){
  animation-delay: 0s;
  width: 80px;
}
.background-icons img:nth-child(5){
  animation-delay: 7s;
  width: 140px;
  animation: icon 16s linear infinite;
}
.background-icons img:nth-child(6){
  animation-delay: 18s;
  width: 120px;
}
.background-icons img:nth-child(7){
  animation-delay: 5s;
  width: 80px;
}